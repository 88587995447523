const data = {
  name: 'Header Slice',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            header_title: {
              html: '<h1>Terms</h1>',
              text: 'Terms',
            },
            header_subtitle: {
              html: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>',
              text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
            },
            has_testimonial: false,
            header_testimonial_hero_image: {
              url: null,
              localFile: null,
            },
            header_testimonial_icon: {
              url: null,
              localFile: null,
            },
            header_testimonial_content: {
              html: '',
              text: '',
            },
            header_testimonial_author: {
              html: '',
              text: '',
            },
          },
        },
      },
    },
    {
      title: 'With Testimonial',
      props: {
        data: {
          primary: {
            header_title: {
              html: '<h1>About Us</h1>',
              text: 'About Us',
            },
            header_subtitle: {
              html: '<p>PerformID is the leading global fintech solution in the card-linked space. PerformID bridges the gap between digital loyalty and payment gateways, delivering a seamless consumer experience.</p>',
              text: 'PerformID is the leading global fintech solution in the card-linked space. PerformID bridges the gap between digital loyalty and payment gateways, delivering a seamless consumer experience.',
            },
            has_testimonial: true,
            header_testimonial_hero_image: {
              url: 'https://images.prismic.io/perform-id/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.png?auto=compress%2Cformat&rect=0%2C0%2C1293%2C592&w=2000&h=916',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'fullWidth',
                    backgroundColor: '#f8f8f8',
                    images: {
                      fallback: {
                        src: '/static/b55b435e8e91b510751628ce88e862cd/d69cf/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.png',
                        srcSet:
                          '/static/b55b435e8e91b510751628ce88e862cd/d52fd/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.png 750w,\n/static/b55b435e8e91b510751628ce88e862cd/06eb5/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.png 1080w,\n/static/b55b435e8e91b510751628ce88e862cd/832f3/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.png 1366w,\n/static/b55b435e8e91b510751628ce88e862cd/d69cf/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.png 1920w',
                        sizes: '100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/b55b435e8e91b510751628ce88e862cd/08c77/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.webp 750w,\n/static/b55b435e8e91b510751628ce88e862cd/5e958/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.webp 1080w,\n/static/b55b435e8e91b510751628ce88e862cd/44ed7/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.webp 1366w,\n/static/b55b435e8e91b510751628ce88e862cd/31cf9/9c1fe2df-f437-4030-b940-5175f433eeaa_about-us-hero.webp 1920w',
                          type: 'image/webp',
                          sizes: '100vw',
                        },
                      ],
                    },
                    width: 1,
                    height: 0.4578125,
                  },
                },
              },
            },
            header_testimonial_icon: {
              url: 'https://perform-id.cdn.prismic.io/perform-id/0e9890b2-44c2-49fa-b105-0d0657ac9976_quote-icon.svg',
              localFile: {
                childImageSharp: null,
              },
            },
            header_testimonial_content: {
              html: '<p>“The payment loyalty space has never been so rewarding for everyone involved. We are pushing the boundaries in the card-linking industry by merging financial payments and merchant sales in the most seamless integration possible. Capitalising on our know-how and cutting edge technology to surprise and delight millions of global customers.”</p>',
              text: '“The payment loyalty space has never been so rewarding for everyone involved. We are pushing the boundaries in the card-linking industry by merging financial payments and merchant sales in the most seamless integration possible. Capitalising on our know-how and cutting edge technology to surprise and delight millions of global customers.”',
            },
            header_testimonial_author: {
              html: '<h4>Alex Short, PerformID CEO</h4>',
              text: 'Alex Short, PerformID CEO',
            },
          },
        },
      },
    },
  ],
};

export default data;
