const data = {
  name: 'Contact',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            contact_title: {
              html: '<h2>Say Hello 👋</h2>',
              text: 'Say Hello 👋',
            },
            contact_subtitle: {
              html: '<p>Contact us using our quick enquiry form and one of our team will be in touch. Alternatively you can email us on contact@performid.com</p>',
              text: 'Contact us using our quick enquiry form and one of our team will be in touch. Alternatively you can email us on contact@performid.com',
            },
            contact_image: {
              url: 'https://images.prismic.io/perform-id/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.png?auto=compress%2Cformat&rect=0%2C0%2C1600%2C612&w=2000&h=765',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'fullWidth',
                    backgroundColor: '#d8c8c8',
                    images: {
                      fallback: {
                        src: '/static/7242c91de1844061be71ae85c514c5b5/bcaba/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.png',
                        srcSet:
                          '/static/7242c91de1844061be71ae85c514c5b5/6dbed/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.png 750w,\n/static/7242c91de1844061be71ae85c514c5b5/af2fe/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.png 1080w,\n/static/7242c91de1844061be71ae85c514c5b5/cdcf0/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.png 1366w,\n/static/7242c91de1844061be71ae85c514c5b5/bcaba/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.png 1920w',
                        sizes: '100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/7242c91de1844061be71ae85c514c5b5/9b509/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.webp 750w,\n/static/7242c91de1844061be71ae85c514c5b5/26681/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.webp 1080w,\n/static/7242c91de1844061be71ae85c514c5b5/6e6d9/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.webp 1366w,\n/static/7242c91de1844061be71ae85c514c5b5/bbeb1/f84deb75-debd-4005-9fdc-ea190668bbf8_coats.webp 1920w',
                          type: 'image/webp',
                          sizes: '100vw',
                        },
                      ],
                    },
                    width: 1,
                    height: 0.3817708333333333,
                  },
                },
              },
            },
          },
        },
      },
    },
  ],
};

export default data;
