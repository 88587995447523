const data = {
  name: 'CTA',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            cta_title: {
              text: 'Join today’s leading payment gateways and card providers in automating merchant funded rewards from hundreds of retailers.',
              html: '<h3>Join today’s leading payment gateways and card providers in automating merchant funded rewards from hundreds of retailers.</h3>',
            },
            cta_link_text: {
              text: 'Contact Us',
            },
            cta_link_slug: {
              url: '/fable',
            },
          },
        },
      },
    },
  ],
};

export default data;
