const data = {
  name: 'Feature Columns',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            feature_column_1_icon: {
              url: 'https://perform-id.cdn.prismic.io/perform-id/95c1e130-6c8b-4fa8-b23e-5604fe2fb245_credit-card.svg',
              localFile: {
                childImageSharp: null,
              },
            },
            feature_column_1_content: {
              text: 'PerformID manage all merchant relations, offer negotiation, offer funding, merchant integrations and customer facing enquires, removing or minimising the need for clients to service their own rewards portals.',
              html: '<p>PerformID manage all merchant relations, offer negotiation, offer funding, merchant integrations and customer facing enquires, removing or minimising the need for clients to service their own rewards portals.</p>',
            },
            feature_column_2_icon: {
              url: 'https://perform-id.cdn.prismic.io/perform-id/678dc2c0-ed25-43c0-920c-956f33d793dd_heart-hand.svg',
              localFile: {
                childImageSharp: null,
              },
            },
            feature_column_2_content: {
              text: 'Our team of multilingual local experts will create a strategy for success that integrates all digital channels. The PerformID model is based on incrementality transparency and efficiency.',
              html: '<p>Our team of multilingual local experts will create a strategy for success that integrates all digital channels. The PerformID model is based on incrementality transparency and efficiency.</p>',
            },
          },
        },
      },
    },
  ],
};

export default data;
