const data = {
  name: 'Testimonials',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            testimonials_title: {
              text: 'What do our partners say about us',
              html: '<h2>What do our partners say about us</h2>',
            },
            background_colour: 'off-white',
          },
          items: [
            {
              testimonial_content: {
                html: '<p>“Partnering with PerformID&#39;s Korean loyalty platform for ShinhanCard and AMEX (myamexshopping.com) has empowered near real-time rewards to exclusive customer segments in the millions. Their technology is an exciting breakthrough for financial services and performance marketing.”</p>',
                text: "“Partnering with PerformID's Korean loyalty platform for ShinhanCard and AMEX (myamexshopping.com) has empowered near real-time rewards to exclusive customer segments in the millions. Their technology is an exciting breakthrough for financial services and performance marketing.”",
              },
              testimonial_name: {
                html: '<h5>Leo Wang</h5>',
                text: 'Leo Wang',
              },
              testimonial_about: {
                html: '<p>Director, Publisher Development Asia Pacific</p>',
                text: 'Director, Publisher Development Asia Pacific',
              },
              testimonial_icon: {
                url: 'https://images.prismic.io/perform-id/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png?auto=compress%2Cformat&rect=0%2C0%2C350%2C350&w=2000&h=2000',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#080808',
                      images: {
                        fallback: {
                          src: '/static/a1981b23e546c628d6e1c9adcb6fc78f/7c9df/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png',
                          srcSet:
                            '/static/a1981b23e546c628d6e1c9adcb6fc78f/27b15/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png 750w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/e7cb1/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png 1080w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/63914/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png 1366w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/7c9df/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/a1981b23e546c628d6e1c9adcb6fc78f/cfe1c/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.webp 750w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/747ef/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.webp 1080w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/aa2d3/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.webp 1366w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/e26db/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 1,
                    },
                  },
                },
              },
              testimonial_company_logo: {
                url: 'https://perform-id.cdn.prismic.io/perform-id/03f32c9e-62ee-4859-8d51-c62ddee28578_rakuten-advertising.svg',
                localFile: {
                  childImageSharp: null,
                },
              },
            },
            {
              testimonial_content: {
                html: '<p>“PerformID has pushed forward card-linked offers enabling affiliate tracked rewards for finance services. Their technology is clearly a new opportunity for merchant to increase their sales with better performance monitoring while increasing customers satisfaction for banks and card networks.”</p>',
                text: '“PerformID has pushed forward card-linked offers enabling affiliate tracked rewards for finance services. Their technology is clearly a new opportunity for merchant to increase their sales with better performance monitoring while increasing customers satisfaction for banks and card networks.”',
              },
              testimonial_name: {
                html: '<h5>Wandrille Lefebvre</h5>',
                text: 'Wandrille Lefebvre',
              },
              testimonial_about: {
                html: '<p>Performance Marketing Director</p>',
                text: 'Performance Marketing Director',
              },
              testimonial_icon: {
                url: 'https://images.prismic.io/perform-id/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png?auto=compress%2Cformat&rect=0%2C0%2C490%2C490&w=2000&h=2000',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#080808',
                      images: {
                        fallback: {
                          src: '/static/5e13289d0c1b371078d49c61bb1cec3b/7c9df/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png',
                          srcSet:
                            '/static/5e13289d0c1b371078d49c61bb1cec3b/27b15/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png 750w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/e7cb1/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png 1080w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/63914/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png 1366w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/7c9df/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/5e13289d0c1b371078d49c61bb1cec3b/cfe1c/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.webp 750w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/747ef/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.webp 1080w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/aa2d3/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.webp 1366w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/e26db/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 1,
                    },
                  },
                },
              },
              testimonial_company_logo: {
                url: 'https://perform-id.cdn.prismic.io/perform-id/fdd72501-96bc-44f6-8597-1303c05a08a8_24s.svg',
                localFile: {
                  childImageSharp: null,
                },
              },
            },
            {
              testimonial_content: {
                html: '<p>“PerformID has introduced a revolutionary way of working, bringing data and attribution together is a game changer for the card-linking industry. The ability to reward customers and track the performance is a must for retailers.”</p>',
                text: '“PerformID has introduced a revolutionary way of working, bringing data and attribution together is a game changer for the card-linking industry. The ability to reward customers and track the performance is a must for retailers.”',
              },
              testimonial_name: {
                html: '<h5>Carolina Paradas</h5>',
                text: 'Carolina Paradas',
              },
              testimonial_about: {
                html: '<p>Global Senior Affiliate &amp; CLO Manager</p>',
                text: 'Global Senior Affiliate & CLO Manager',
              },
              testimonial_icon: {
                url: 'https://images.prismic.io/perform-id/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png?auto=compress%2Cformat&rect=0%2C0%2C490%2C490&w=2000&h=2000',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#080808',
                      images: {
                        fallback: {
                          src: '/static/4d35fadae432d9a0a32862c0a6cbe77c/7c9df/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png',
                          srcSet:
                            '/static/4d35fadae432d9a0a32862c0a6cbe77c/27b15/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png 750w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/e7cb1/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png 1080w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/63914/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png 1366w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/7c9df/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/4d35fadae432d9a0a32862c0a6cbe77c/cfe1c/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.webp 750w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/747ef/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.webp 1080w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/aa2d3/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.webp 1366w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/e26db/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 1,
                    },
                  },
                },
              },
              testimonial_company_logo: {
                url: 'https://perform-id.cdn.prismic.io/perform-id/dba68503-33ea-4cae-8e91-0d80e17a3c1b_harvey-nichols.svg',
                localFile: {
                  childImageSharp: null,
                },
              },
            },
          ],
        },
      },
    },
    {
      title: 'White Background',
      props: {
        data: {
          primary: {
            testimonials_title: {
              text: 'What do our partners say about us',
              html: '<h2>What do our partners say about us</h2>',
            },
            background_colour: 'white',
          },
          items: [
            {
              testimonial_content: {
                html: '<p>“Partnering with PerformID&#39;s Korean loyalty platform for ShinhanCard and AMEX (myamexshopping.com) has empowered near real-time rewards to exclusive customer segments in the millions. Their technology is an exciting breakthrough for financial services and performance marketing.”</p>',
                text: "“Partnering with PerformID's Korean loyalty platform for ShinhanCard and AMEX (myamexshopping.com) has empowered near real-time rewards to exclusive customer segments in the millions. Their technology is an exciting breakthrough for financial services and performance marketing.”",
              },
              testimonial_name: {
                html: '<h5>Leo Wang</h5>',
                text: 'Leo Wang',
              },
              testimonial_about: {
                html: '<p>Director, Publisher Development Asia Pacific</p>',
                text: 'Director, Publisher Development Asia Pacific',
              },
              testimonial_icon: {
                url: 'https://images.prismic.io/perform-id/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png?auto=compress%2Cformat&rect=0%2C0%2C350%2C350&w=2000&h=2000',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#080808',
                      images: {
                        fallback: {
                          src: '/static/a1981b23e546c628d6e1c9adcb6fc78f/7c9df/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png',
                          srcSet:
                            '/static/a1981b23e546c628d6e1c9adcb6fc78f/27b15/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png 750w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/e7cb1/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png 1080w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/63914/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png 1366w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/7c9df/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/a1981b23e546c628d6e1c9adcb6fc78f/cfe1c/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.webp 750w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/747ef/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.webp 1080w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/aa2d3/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.webp 1366w,\n/static/a1981b23e546c628d6e1c9adcb6fc78f/e26db/7521398e-6d19-46ef-b277-819d162af0b7_leo-wang-avatar.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 1,
                    },
                  },
                },
              },
              testimonial_company_logo: {
                url: 'https://perform-id.cdn.prismic.io/perform-id/03f32c9e-62ee-4859-8d51-c62ddee28578_rakuten-advertising.svg',
                localFile: {
                  childImageSharp: null,
                },
              },
            },
            {
              testimonial_content: {
                html: '<p>“PerformID has pushed forward card-linked offers enabling affiliate tracked rewards for finance services. Their technology is clearly a new opportunity for merchant to increase their sales with better performance monitoring while increasing customers satisfaction for banks and card networks.”</p>',
                text: '“PerformID has pushed forward card-linked offers enabling affiliate tracked rewards for finance services. Their technology is clearly a new opportunity for merchant to increase their sales with better performance monitoring while increasing customers satisfaction for banks and card networks.”',
              },
              testimonial_name: {
                html: '<h5>Wandrille Lefebvre</h5>',
                text: 'Wandrille Lefebvre',
              },
              testimonial_about: {
                html: '<p>Performance Marketing Director</p>',
                text: 'Performance Marketing Director',
              },
              testimonial_icon: {
                url: 'https://images.prismic.io/perform-id/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png?auto=compress%2Cformat&rect=0%2C0%2C490%2C490&w=2000&h=2000',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#080808',
                      images: {
                        fallback: {
                          src: '/static/5e13289d0c1b371078d49c61bb1cec3b/7c9df/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png',
                          srcSet:
                            '/static/5e13289d0c1b371078d49c61bb1cec3b/27b15/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png 750w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/e7cb1/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png 1080w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/63914/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png 1366w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/7c9df/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/5e13289d0c1b371078d49c61bb1cec3b/cfe1c/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.webp 750w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/747ef/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.webp 1080w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/aa2d3/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.webp 1366w,\n/static/5e13289d0c1b371078d49c61bb1cec3b/e26db/cefa4bea-a410-4a16-b32c-446f6091efbd_wandrille-lebfevre-avatar.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 1,
                    },
                  },
                },
              },
              testimonial_company_logo: {
                url: 'https://perform-id.cdn.prismic.io/perform-id/fdd72501-96bc-44f6-8597-1303c05a08a8_24s.svg',
                localFile: {
                  childImageSharp: null,
                },
              },
            },
            {
              testimonial_content: {
                html: '<p>“PerformID has introduced a revolutionary way of working, bringing data and attribution together is a game changer for the card-linking industry. The ability to reward customers and track the performance is a must for retailers.”</p>',
                text: '“PerformID has introduced a revolutionary way of working, bringing data and attribution together is a game changer for the card-linking industry. The ability to reward customers and track the performance is a must for retailers.”',
              },
              testimonial_name: {
                html: '<h5>Carolina Paradas</h5>',
                text: 'Carolina Paradas',
              },
              testimonial_about: {
                html: '<p>Global Senior Affiliate &amp; CLO Manager</p>',
                text: 'Global Senior Affiliate & CLO Manager',
              },
              testimonial_icon: {
                url: 'https://images.prismic.io/perform-id/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png?auto=compress%2Cformat&rect=0%2C0%2C490%2C490&w=2000&h=2000',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#080808',
                      images: {
                        fallback: {
                          src: '/static/4d35fadae432d9a0a32862c0a6cbe77c/7c9df/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png',
                          srcSet:
                            '/static/4d35fadae432d9a0a32862c0a6cbe77c/27b15/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png 750w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/e7cb1/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png 1080w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/63914/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png 1366w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/7c9df/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/4d35fadae432d9a0a32862c0a6cbe77c/cfe1c/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.webp 750w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/747ef/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.webp 1080w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/aa2d3/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.webp 1366w,\n/static/4d35fadae432d9a0a32862c0a6cbe77c/e26db/f05f8d38-9249-4438-b005-c1020c6a84dd_carolina-paradas-avatar.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 1,
                    },
                  },
                },
              },
              testimonial_company_logo: {
                url: 'https://perform-id.cdn.prismic.io/perform-id/dba68503-33ea-4cae-8e91-0d80e17a3c1b_harvey-nichols.svg',
                localFile: {
                  childImageSharp: null,
                },
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
