const data = {
  name: 'USP Grid',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            usp_grid_title: {
              html: '<h2>What makes us different</h2>',
              text: 'What makes us different',
            },
            usp_1_title: {
              html: '<h4>Improved Cardlinked Tracking</h4>',
              text: 'Improved Cardlinked Tracking',
            },
            usp_1_content: {
              html: '<p>Traditional card-linking methods are fast becoming outdated. The demands of the modern merchant dictate that our industry need to meet product level requirements or commission premium or new customers with higher offers. Tracking and attribution are key to measurement in the digital era.</p>',
              text: 'Traditional card-linking methods are fast becoming outdated. The demands of the modern merchant dictate that our industry need to meet product level requirements or commission premium or new customers with higher offers. Tracking and attribution are key to measurement in the digital era.',
            },
            usp_1_icon: {
              url: 'https://perform-id.cdn.prismic.io/perform-id/3aec87d7-dd02-461b-93f1-34aa7dac4d3e_coins-2.svg',
              localFile: {
                childImageSharp: null,
              },
            },
            usp_2_title: {
              html: '<h4>Near-real time Customer Rewards</h4>',
              text: 'Near-real time Customer Rewards',
            },
            usp_2_content: {
              html: '<p>Industry standard is 60 - 90 days. Customers can now enjoy their rewards within a matter of minutes. We keep them updated at every step of the way through live  notifications. This enables the customer to shop in confidence and keeps them engaged with their favorite payment method.</p>',
              text: 'Industry standard is 60 - 90 days. Customers can now enjoy their rewards within a matter of minutes. We keep them updated at every step of the way through live  notifications. This enables the customer to shop in confidence and keeps them engaged with their favorite payment method.',
            },
            usp_2_icon: {
              url: 'https://perform-id.cdn.prismic.io/perform-id/d50bd441-41d1-4f09-86d7-4eefd059ffb5_gauge-3.svg',
              localFile: {
                childImageSharp: null,
              },
            },
            usp_cta_title: {
              html: '<h4>A New Loyalty Offering</h4>',
              text: 'A New Loyalty Offering',
            },
            usp_cta_content: {
              html: '<p>Financial services have never before been able to connect with users in a more meaningful and personalised way and merchants have never worked with financial partners with such ease. A win for all involved, with customer satisfaction at the core.</p>',
              text: 'Financial services have never before been able to connect with users in a more meaningful and personalised way and merchants have never worked with financial partners with such ease. A win for all involved, with customer satisfaction at the core.',
            },
            usp_cta_link_text: {
              text: 'Contact Us',
            },
            usp_cta_link_slug: {
              url: '/fable',
            },
            usp_cta_icon: {
              url: 'https://perform-id.cdn.prismic.io/perform-id/85d428ce-3dcf-4bb0-a321-b05f65e2d143_logo-icon.svg',
              localFile: {
                childImageSharp: null,
              },
            },
          },
        },
      },
    },
  ],
};

export default data;
