const data = {
  name: 'Two Cols Text Image',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            two_cols_text_image_title: {
              html: '<h2>The PerformID edge</h2>',
              text: 'The PerformID edge',
            },
            two_cols_text_image_content: {
              html: '<p>Supercharge your share of wallet and customer spend with near real-time customer rewards powered by PerformID technology.</p><ul><li>Over 50% higher merchant funding compared to industry average</li><li>Retain and engage cardholders with exclusive offers and benefits</li><li>Target premium customer segments with better rewards</li><li>Transparent tracking over partial returns and commissions</li><li>Fully managed merchant relations, marketing campaigns and multilingual customer service</li><li>Automated merchant invoicing and reconciliation</li></ul>',
              text: 'Supercharge your share of wallet and customer spend with near real-time customer rewards powered by PerformID technology. Over 50% higher merchant funding compared to industry average Retain and engage cardholders with exclusive offers and benefits Target premium customer segments with better rewards Transparent tracking over partial returns and commissions Fully managed merchant relations, marketing campaigns and multilingual customer service Automated merchant invoicing and reconciliation',
            },
            two_cols_text_image_image: {
              url: 'https://images.prismic.io/perform-id/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI+Congratulations.png?auto=compress%2Cformat&rect=0%2C0%2C1263%2C1003&w=2000&h=1588',
              localFile: {
                childImageSharp: {
                  gatsbyImageData: {
                    layout: 'fullWidth',
                    backgroundColor: '#f8f8f8',
                    images: {
                      fallback: {
                        src: '/static/d6942b8c5e42c32a3173be593ffb65d1/3f23b/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI%2BCongratulations.png',
                        srcSet:
                          '/static/d6942b8c5e42c32a3173be593ffb65d1/9d344/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI%2BCongratulations.png 750w,\n/static/d6942b8c5e42c32a3173be593ffb65d1/bc076/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI%2BCongratulations.png 1080w,\n/static/d6942b8c5e42c32a3173be593ffb65d1/f16f8/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI%2BCongratulations.png 1366w,\n/static/d6942b8c5e42c32a3173be593ffb65d1/3f23b/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI%2BCongratulations.png 1920w',
                        sizes: '100vw',
                      },
                      sources: [
                        {
                          srcSet:
                            '/static/d6942b8c5e42c32a3173be593ffb65d1/d9606/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI%2BCongratulations.webp 750w,\n/static/d6942b8c5e42c32a3173be593ffb65d1/ec982/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI%2BCongratulations.webp 1080w,\n/static/d6942b8c5e42c32a3173be593ffb65d1/211e6/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI%2BCongratulations.webp 1366w,\n/static/d6942b8c5e42c32a3173be593ffb65d1/1d6a8/2d1b031a-d484-4e42-95b9-a83103bdf5c7_UI%2BCongratulations.webp 1920w',
                          type: 'image/webp',
                          sizes: '100vw',
                        },
                      ],
                    },
                    width: 1,
                    height: 0.79375,
                  },
                },
              },
            },
          },
        },
      },
    },
  ],
};

export default data;
