const data = {
  name: 'Metrics Compact',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            metric_column_1_stat: {
              text: '20M+',
              html: '<h2>20M+</h2>',
            },
            metric_column_1_subtitle: {
              text: 'Tracked sales annually',
              html: '<p>Tracked sales annually</p>',
            },
            metric_column_2_stat: {
              text: '12M+',
              html: '<h2>12M+</h2>',
            },
            metric_column_2_subtitle: {
              text: 'Customer reach',
              html: '<p>Customer reach</p>',
            },
            metric_column_3_stat: {
              text: '200+',
              html: '<h2>200+</h2>',
            },
            metric_column_3_subtitle: {
              text: 'Merchant partnerships',
              html: '<p>Merchant partnerships</p>',
            },
            metric_column_4_stat: {
              text: '1.7x',
              html: '<h2>1.7x</h2>',
            },
            metric_column_4_subtitle: {
              text: 'Higher average order value',
              html: '<p>Higher average order value</p>',
            },
          },
        },
      },
    },
  ],
};

export default data;
