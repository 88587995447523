const data = {
  name: 'Our Offices',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            background_colour: 'off-white',
            our_offices_title: {
              html: '<h2>Our Offices</h2>',
              text: 'Our Offices',
            },
            office_location_1_title: {
              html: '<h4>Sydney office</h4>',
              text: 'Sydney office',
            },
            office_location_1_address: {
              html: '<p>Level 1- 85 William Street, DARLINGHURST NSW 2010</p>',
              text: 'Level 1- 85 William Street, DARLINGHURST NSW 2010',
            },
            office_location_2_title: {
              html: '<h4>Seoul office</h4>',
              text: 'Seoul office',
            },
            office_location_2_address: {
              html: '<p>83 Uisadang-dareo Yeoeuido-Dong, Seoul</p>',
              text: '83 Uisadang-dareo Yeoeuido-Dong, Seoul',
            },
          },
        },
      },
    },
  ],
};

export default data;
