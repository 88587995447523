const data = {
  name: 'Jobs Board',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            jobs_board_title: {
              text: 'Our current vacancies',
              html: '<h2>Our current vacancies</h2>',
            },
            jobs_board_subtitle: {
              text: 'Check our featured jobs from popular companies. Start applying now.',
              html: '<p>Check our featured jobs from popular companies. Start applying now.</p>',
            },
            jobs_board_card_cta_linktext: {
              text: 'Learn More',
              html: '<p>Learn More</p>',
            },
          },
          items: [
            {
              jobs_board_card_top_row_label: {
                html: '<p>Sydney</p>',
                text: 'Sydney',
              },
              jobs_board_card_title: {
                html: '<h4>Full-Stack Web Developer</h4>',
                text: 'Full-Stack Web Developer',
              },
              jobs_board_card_subtitle: {
                html: '<p>Small blurb about the job</p>',
                text: 'Small blurb about the job',
              },
              jobs_board_card_cta_link_slug: {
                url: '/fable',
              },
              jobs_board_card_date_posted: 'a month ago',
            },
            {
              jobs_board_card_top_row_label: {
                html: '<p>Remote</p>',
                text: 'Remote',
              },
              jobs_board_card_title: {
                html: '<h4>Senior Project Manager</h4>',
                text: 'Senior Project Manager',
              },
              jobs_board_card_subtitle: {
                html: '<p>Small blurb about the job</p>',
                text: 'Small blurb about the job',
              },
              jobs_board_card_cta_link_slug: {
                url: '/fable',
              },
              jobs_board_card_date_posted: 'a month ago',
            },
            {
              jobs_board_card_top_row_label: {
                html: '<p>Sydney</p>',
                text: 'Sydney',
              },
              jobs_board_card_title: {
                html: '<h4>Junior Graphic Designer</h4>',
                text: 'Junior Graphic Designer',
              },
              jobs_board_card_subtitle: {
                html: '<p>Small blurb about the job</p>',
                text: 'Small blurb about the job',
              },
              jobs_board_card_cta_link_slug: {
                url: '/fable',
              },
              jobs_board_card_date_posted: 'a month ago',
            },
            {
              jobs_board_card_top_row_label: {
                html: '<p>Sydney</p>',
                text: 'Sydney',
              },
              jobs_board_card_title: {
                html: '<h4>Junior Project Manager</h4>',
                text: 'Junior Project Manager',
              },
              jobs_board_card_subtitle: {
                html: '<p>Small blurb about the job</p>',
                text: 'Small blurb about the job',
              },
              jobs_board_card_cta_link_slug: {
                url: '/fable',
              },
              jobs_board_card_date_posted: '2 months ago',
            },
            {
              jobs_board_card_top_row_label: {
                html: '<p>Remote</p>',
                text: 'Remote',
              },
              jobs_board_card_title: {
                html: '<h4>Senior Graphic Designer</h4>',
                text: 'Senior Graphic Designer',
              },
              jobs_board_card_subtitle: {
                html: '<p>Small blurb about the job</p>',
                text: 'Small blurb about the job',
              },
              jobs_board_card_cta_link_slug: {
                url: '/fable',
              },
              jobs_board_card_date_posted: 'a month ago',
            },
            {
              jobs_board_card_top_row_label: {
                html: '<p>Remote</p>',
                text: 'Remote',
              },
              jobs_board_card_title: {
                html: '<h4>Font End Web Developer</h4>',
                text: 'Font End Web Developer',
              },
              jobs_board_card_subtitle: {
                html: '<p>Small blurb about the job</p>',
                text: 'Small blurb about the job',
              },
              jobs_board_card_cta_link_slug: {
                url: '/fable',
              },
              jobs_board_card_date_posted: 'a month ago',
            },
          ],
        },
      },
    },
  ],
};

export default data;
