const data = {
  name: 'Image Grid',
  variations: [
    {
      title: 'Default',
      props: {
        data: {
          primary: {
            image_grid_title: {
              html: '<h2>Why work at PerformID?</h2>',
              text: 'Why work at PerformID?',
            },
            image_grid_subtitle: {
              html: '<p></p>',
              text: '',
            },
          },
          items: [
            {
              image_grid_image: {
                url: 'https://images.prismic.io/perform-id/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.png?auto=compress%2Cformat&rect=0%2C0%2C525%2C393&w=2000&h=1497',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#687878',
                      images: {
                        fallback: {
                          src: '/static/87e7b003ae1e592f011f745544541c16/74d9f/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.png',
                          srcSet:
                            '/static/87e7b003ae1e592f011f745544541c16/1fcb5/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.png 750w,\n/static/87e7b003ae1e592f011f745544541c16/fbd43/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.png 1080w,\n/static/87e7b003ae1e592f011f745544541c16/ad32e/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.png 1366w,\n/static/87e7b003ae1e592f011f745544541c16/74d9f/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/87e7b003ae1e592f011f745544541c16/51823/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.webp 750w,\n/static/87e7b003ae1e592f011f745544541c16/ebdae/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.webp 1080w,\n/static/87e7b003ae1e592f011f745544541c16/a4f3e/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.webp 1366w,\n/static/87e7b003ae1e592f011f745544541c16/c32ad/3edce277-6c6c-46c8-b3b3-21bf80b66697_engineering.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 0.7484375,
                    },
                  },
                },
              },
              image_grid_image_title: {
                html: '<h4>Engineering</h4>',
                text: 'Engineering',
              },
              image_grid_image_subtitle: {
                html: '<h4>Be the brain that powers us</h4>',
                text: 'Be the brain that powers us',
              },
              image_grid_image_content: {
                html: '<p>We encourage our engineers to be disruptors in the industry. Embracing the interests and talents of each engineer to create a truly powerful fintech solution that makes real customer impact.</p>',
                text: 'We encourage our engineers to be disruptors in the industry. Embracing the interests and talents of each engineer to create a truly powerful fintech solution that makes real customer impact.',
              },
            },
            {
              image_grid_image: {
                url: 'https://images.prismic.io/perform-id/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.png?auto=compress%2Cformat&rect=0%2C0%2C653%2C509&w=2000&h=1559',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#f8f8f8',
                      images: {
                        fallback: {
                          src: '/static/ee1c045aa4e5e28f90f7a56afbc1eb0a/10cbe/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.png',
                          srcSet:
                            '/static/ee1c045aa4e5e28f90f7a56afbc1eb0a/f0b8a/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.png 750w,\n/static/ee1c045aa4e5e28f90f7a56afbc1eb0a/d3fdf/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.png 1080w,\n/static/ee1c045aa4e5e28f90f7a56afbc1eb0a/7b7e9/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.png 1366w,\n/static/ee1c045aa4e5e28f90f7a56afbc1eb0a/10cbe/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/ee1c045aa4e5e28f90f7a56afbc1eb0a/5f7ab/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.webp 750w,\n/static/ee1c045aa4e5e28f90f7a56afbc1eb0a/7be5e/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.webp 1080w,\n/static/ee1c045aa4e5e28f90f7a56afbc1eb0a/067be/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.webp 1366w,\n/static/ee1c045aa4e5e28f90f7a56afbc1eb0a/df18d/51028d4a-55b6-44a0-9aa1-b03026275dd2_customer_support.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 0.7796875,
                    },
                  },
                },
              },
              image_grid_image_title: {
                html: '<h4>Customer Support</h4>',
                text: 'Customer Support',
              },
              image_grid_image_subtitle: {
                html: '<h4>The heart of our business</h4>',
                text: 'The heart of our business',
              },
              image_grid_image_content: {
                html: '<p>Our customers are our most important asset. We are calling all passionate individuals who share our vision of customer success.</p>',
                text: 'Our customers are our most important asset. We are calling all passionate individuals who share our vision of customer success.',
              },
            },
            {
              image_grid_image: {
                url: 'https://images.prismic.io/perform-id/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.png?auto=compress%2Cformat&rect=0%2C0%2C525%2C507&w=2000&h=1931',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#d8d8d8',
                      images: {
                        fallback: {
                          src: '/static/ed0ab7c5c6526fa37853d65a52f3146d/eb5d4/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.png',
                          srcSet:
                            '/static/ed0ab7c5c6526fa37853d65a52f3146d/10e86/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.png 750w,\n/static/ed0ab7c5c6526fa37853d65a52f3146d/456c1/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.png 1080w,\n/static/ed0ab7c5c6526fa37853d65a52f3146d/ecce8/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.png 1366w,\n/static/ed0ab7c5c6526fa37853d65a52f3146d/eb5d4/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/ed0ab7c5c6526fa37853d65a52f3146d/3361d/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.webp 750w,\n/static/ed0ab7c5c6526fa37853d65a52f3146d/b39e3/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.webp 1080w,\n/static/ed0ab7c5c6526fa37853d65a52f3146d/13be7/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.webp 1366w,\n/static/ed0ab7c5c6526fa37853d65a52f3146d/dca4f/ca8f61e7-12c4-49ba-8928-9f8f4b257f48_graduates.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 0.9656250000000001,
                    },
                  },
                },
              },
              image_grid_image_title: {
                html: '<h4>Graduates</h4>',
                text: 'Graduates',
              },
              image_grid_image_subtitle: {
                html: '<h4>Kickstart your career</h4>',
                text: 'Kickstart your career',
              },
              image_grid_image_content: {
                html: '<p>Take a hold of your own future and grow with us. As a rapidly growing start-up, we welcome new graduates who show a passion and hunger for learning and personal development.</p>',
                text: 'Take a hold of your own future and grow with us. As a rapidly growing start-up, we welcome new graduates who show a passion and hunger for learning and personal development.',
              },
            },
            {
              image_grid_image: {
                url: 'https://images.prismic.io/perform-id/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.png?auto=compress%2Cformat&rect=0%2C0%2C653%2C419&w=2000&h=1283',
                localFile: {
                  childImageSharp: {
                    gatsbyImageData: {
                      layout: 'fullWidth',
                      backgroundColor: '#080808',
                      images: {
                        fallback: {
                          src: '/static/f7f6c0870f01254098ee1e7231a36040/15cf0/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.png',
                          srcSet:
                            '/static/f7f6c0870f01254098ee1e7231a36040/8db98/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.png 750w,\n/static/f7f6c0870f01254098ee1e7231a36040/18b1b/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.png 1080w,\n/static/f7f6c0870f01254098ee1e7231a36040/46d55/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.png 1366w,\n/static/f7f6c0870f01254098ee1e7231a36040/15cf0/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.png 1920w',
                          sizes: '100vw',
                        },
                        sources: [
                          {
                            srcSet:
                              '/static/f7f6c0870f01254098ee1e7231a36040/0f0eb/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.webp 750w,\n/static/f7f6c0870f01254098ee1e7231a36040/195d3/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.webp 1080w,\n/static/f7f6c0870f01254098ee1e7231a36040/308a9/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.webp 1366w,\n/static/f7f6c0870f01254098ee1e7231a36040/d3fcb/2a55ad69-fa43-422f-9df0-8d0d1605367b_marketing.webp 1920w',
                            type: 'image/webp',
                            sizes: '100vw',
                          },
                        ],
                      },
                      width: 1,
                      height: 0.6416666666666666,
                    },
                  },
                },
              },
              image_grid_image_title: {
                html: '<h4>Marketing</h4>',
                text: 'Marketing',
              },
              image_grid_image_subtitle: {
                html: '<h4>Work with the best brands in digital</h4>',
                text: 'Work with the best brands in digital',
              },
              image_grid_image_content: {
                html: '<p>Working with hundreds of international top brands, our marketing team is world-class and borderless. We welcome strategic thinkers who want to challenge traditional marketing methods.</p>',
                text: 'Working with hundreds of international top brands, our marketing team is world-class and borderless. We welcome strategic thinkers who want to challenge traditional marketing methods.',
              },
            },
          ],
        },
      },
    },
  ],
};

export default data;
